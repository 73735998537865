import React from "react"
import styled from "styled-components"
import GatsbyImage, { FluidObject } from "gatsby-image"

interface IBrandBoxProps {
  className?: string,
  children?: string
  title: string
  logo?: FluidObject
  gallery: JSX.Element
  link?: string
  content: string
}

const BrandBox: React.FC<IBrandBoxProps> = ({ className, title, gallery, link, content, logo }) => {
  return (
    <div className={className}>
      <div className="brand-page__member-pic">
        {gallery}
        <div className="brand-page__member-info">
          <h1 className="title">{title}</h1>
          <div dangerouslySetInnerHTML={{__html: '<p>' + content + '<p>'}} />
          {link ? <a target="blank" href={link} className="btn forshowcase">Visit Site</a> : '' }
        </div>
        <div className="brand-page__logo">
          {logo &&  <GatsbyImage fluid={logo} alt="" />}
        </div>
      </div>
    </div>
  )
}

export default styled(BrandBox)`
  color:black;
  .title {
    font-family: 'Bankgothic Regular',sans-serif;
    color: red;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .brand-page {
    &__member-pic {
      padding: 3%;
      background: #E5E5E5;

      img {
        display: block;
      }
    }

    &__member-info {
      position: relative;
      margin-top:20px;
      span {
        display: block;
        
      }

      padding: 35px 10px;
      @media screen and (min-width: 768px) {
        padding: 35px 45px;
      }
      background: #f1f1f1;

      p {
        line-height: 27px;
      }

    }

    &__logo {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 40%;
      padding: 8px 0;
      background: #f1f1f1;
      z-index: 100;
    }
  }

  //Swipper for brands
  .swiper-container-brands {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;

    &.gallery-thumbs {
      display: none;
      @media screen and (min-width: 900px) {
        display: block;
      }
    }
  }

  .gallery-top {
    height: 80%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0 0;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-button-prev-brand {
    position: absolute;
    z-index: 300;
    left: 10% !important;
    top: 50%;
    margin-top: 0 !important;

    &:before {
      font-family: 'Font Awesome 5 Free',sans-serif;
      content: '\\f053';
      font-style: normal;
      font-weight: 600;
      color: #f1f1f1;
      font-size: 35px;
    }

    background-image: none !important;
  }

  .swiper-button-next-brand {
    position: absolute;
    z-index: 300;
    background-image: none !important;
    right: 10% !important;
    top: 50%;
    margin-top: 0 !important;

    &:before {
      font-family: 'Font Awesome 5 Free',sans-serif;
      content: '\\f054';
      font-style: normal;
      font-weight: 600;
      color: #f1f1f1;
      font-size: 35px;
    }
  }
`
