import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { graphql, PageProps } from "gatsby"
import SEO from "../../components/seo"
import BrandBox from "../../components/BrandBox"
// @ts-ignore
import FimesGallery from "../../components/SwiperSlider/FimesGallery"
import { FluidObject } from "gatsby-image"

interface IFimesBrandProps extends PageProps<{
  fimesLogo: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}> {
  className?: string
}

const content = `
<p>Always using the finest materials Fimes beds exude elegance yet meet very high standards of comfort.
<br>
Every inch of a wardrobe or closet designed by Fimes is a carefully orchestrated symphony of style, functionality and premiere quality.
<br>
Fimes approaches every area of the bedroom space with unprecedented attention to detail, maximizing its potential, 
but offering extraordinary design solutions backed up by years of experience and tradition. 
<br>
Credibility, quality and innovation are our priorities on our quest to meet our customer’s needs. 
Fimes furniture in your home is a tangible sign that beauty is here to stay.
</p>
`

const Fimes: React.FC<IFimesBrandProps> = ({ className, path, data }) => {
  return (
    <Layout lang="en" page={path}>
      <SEO
        title="Fimes specializes in night area furniture and innovative and beds, wardrobes, walk-in closet sand
complements."
        description="Fimes is an Italian fine furniture manufacturer that offers premiere quality and style"
      />
      <div className={className}>
        <section className="section brand-page">
            <BrandBox
              gallery={<FimesGallery />}
              title="Credibility, quality and innovation"
              content={content}
              logo={data.fimesLogo.childImageSharp.fluid}
              link="http://www.fimes.com/en/index.php"
            />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    fimesLogo: file(relativePath: {eq: "ci_fimeslogo4x.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default styled(Fimes)`
  top: 0;
  padding-top: 25px;
  position:relative;
  max-width: 1200px;
  margin: 50px auto auto auto;
  @media screen and (min-width: 1051px) {
    margin-top: 80px;
  }
  padding-bottom: 100px;
`
