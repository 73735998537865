import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
// import GatsbyImage  from "gatsby-image"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { NavNextButton, NavPrevtButton } from "./SliderNavigationButtons"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import SwiperCore, { Navigation , Thumbs, EffectFade } from 'swiper/core';

SwiperCore.use([Navigation, Thumbs, EffectFade]);
const FimesGallery = ({galleryImages}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const data = useStaticQuery(graphql`
    query FimesGalleryImages {
      placeholderImages: allFile(filter: {relativeDirectory: {eq: "brands/fimes"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const fluidImages = data.placeholderImages.nodes.map(pi => {
    return pi.childImageSharp.fluid
  })
  const gallerySwiperParams = {
    style: {height: "100%"},
    navigation: {
      nextEl: '.fimes-gallery-btn-next',
      prevEl: '.fimes-gallery-btn-prev',
    },
    effect: 'fade'
  };
  const thumbSwiperParams = {
    style: {
      marginTop: "20px"
    },
    spaceBetween: 10,
    centeredSlides: false,
    slidesPerView: 6,
    touchRatio: 0.2,
    slideToClickedSlide: true
  }
  return (
    <div>
      <Swiper {...gallerySwiperParams} thumbs={{ swiper: thumbsSwiper }}>
        {galleryImages && galleryImages.length > 0 ? galleryImages.map((fi, index) => {
          const galleryIMAGE = getImage(fi.galleryImage)
          return <SwiperSlide key={fi.galleryImage.uid + fi.galleryImage.name + index}
          >
            {/*<GatsbyImage fluid={fi.galleryImage.childImageSharp.fluid} />*/}
            <GatsbyImage alt={fi.galleryImage.name || ''} image={galleryIMAGE} />
          </SwiperSlide>
        }) : fluidImages.map((fi, index) => {
          return <SwiperSlide key={`slideContent-${index}`}
          >
            <GatsbyImage fluid={fi} />
          </SwiperSlide>
        })}
        <NavPrevtButton addclass="fimes-gallery-btn-prev" />
        <NavNextButton addclass="fimes-gallery-btn-next" />
      </Swiper>
      <Swiper { ...thumbSwiperParams } onSwiper={setThumbsSwiper}>
        {galleryImages && galleryImages.length > 0 ? galleryImages.map((fi, index) => {
          const img = getImage(fi.galleryImage)
          return <SwiperSlide key={fi.galleryImage.uid + fi.galleryImage.name + index + "thumb"}
          >
            <GatsbyImage image={img} alt="" />
          </SwiperSlide>
        }) : fluidImages.map((fi, index) => {
          return <SwiperSlide key={`slideContent-${index}`}
          >
            <GatsbyImage image={fi.galleryImage} alt="" />
          </SwiperSlide>
        })}
      </Swiper>
    </div>
  );
};
export default FimesGallery;
